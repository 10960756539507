<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="8">
        <!-- 搜索与添加区域 -->
        <el-input placeholder="分类名称" v-model="listQuery.name" clearable @clear="search">
          <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
        </el-input>
      </el-col>
      <el-col :span="2">
        <el-button type="primary" @click="onAddCategory">添加分类</el-button>
      </el-col>
      <!--<el-col :span="2">
        <el-button type="primary" @click="onWholeCategory">完整分类</el-button>
      </el-col>-->
    </el-row>

    <!-- 添加分类的对话框 -->
    <el-dialog
      title="添加分类"
      :visible.sync="addDialogVisible"
      width="50%"
      @close="addCategoryDialogClose">
      <el-form :model="addCategoryForm" :rules="categoryFormRules" ref="addCategoryFormRef" label-width="100px">
        <el-form-item label="父级分类">
            <el-select @clear="clearSelect" v-model="addCategoryForm.parentId" clearable filterable :placeholder="placeholder" style="width: 100%">
              <el-option v-for="option in options" :key="option.value" :label="option.label" :value="option.value"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="分类名称" prop="name">
          <el-input v-model="addCategoryForm.name"></el-input>
        </el-form-item>
        <el-form-item label="图标" label-width="100px" prop="logo" v-if="!addCategoryForm.parentId">
          <el-upload
            class="avatar-uploader"
            :action="uploadUrl"
            :show-file-list="false"
            :on-success="handleUploadSuccess"
            :headers="imgUploadHeaders"
          >
            <img v-if="imageUrl" :src="imageUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addCategory">确 定</el-button>
      </span>
    </el-dialog>
  </div>

</template>

<script>
import {
  addCategoryRequest, getCategoriesListRequest
} from '../../../network/goods'

export default {
  name: 'CategoryAdd',
  props: {
    listQuery: {
      type: Object,
      default () {
        return {
          name: '', // 分类名称
          page: 1, // 当前页
          size: 10 // 每页显示多少条数据
        }
      }
    }
  },
  data () {
    return {
      addDialogVisible: false, // 控制添加分类对话框的显示与隐藏
      addCategoryForm: {
        name: '',
        parentId: '', // 父级分类的id
        logo: '',
        level: 1,
        status: 1
      },
      options: [], // 所有选项
      placeholder: '请选择分类',
      categoryFormRules: {
        name: [
          {
            required: true,
            message: '请输入分类名称',
            trigger: 'blur'
          }
        ]
      },
      uploadUrl: window.g.MEDIA_URL + '/upload',
      imgUploadHeaders: { // 上传图片控件的header
        Authorization: sessionStorage.getItem('token')
      },
      imageUrl: '',
      disabled: true
    }
  },
  methods: {
    search () {
      this.$emit('category-list')
    },
    onAddCategory () {
      this.addDialogVisible = true
      getCategoriesListRequest().then(res => {
        if (res.status !== 200) {
          return this.alertMessage('获取分类列表失败', 'error')
        }
        const data = res.data.data.list
        this.options = []
        for (const item of data) {
          this.options.push({ label: item.name, value: item.categoryId })
        }
      })
    },
    // 点击按钮添加新的分类
    addCategory () {
      console.log('add category:', this.addCategoryForm)
      // eslint-disable-next-line no-unreachable
      this.$refs.addCategoryFormRef.validate(valid => {
        if (!valid) {
          return this.alertMessage('添加分类参数有误', 'error')
        }
        if (this.addCategoryForm.parentId === '') {
          this.addCategoryForm.parentId = 0
          this.addCategoryForm.level = 1
        } else {
          this.addCategoryForm.parentId = parseInt(this.addCategoryForm.parentId)
          this.addCategoryForm.level = 2
        }
        addCategoryRequest(this.addCategoryForm).then(res => {
          if (res.status !== 200) {
            return this.alertMessage('添加分类失败', 'error')
          }
          this.alertMessage('添加分类成功', 'success')
          this.$emit('category-list')
          this.addCategoryForm.parentId = ''
          this.addDialogVisible = false
        })
      })
    },

    // 监听对话框的关闭事件，重置表单数据
    addCategoryDialogClose () {
      this.$refs.addCategoryFormRef.resetFields()
      this.imageUrl = ''
    },
    clearSelect () {
      this.addCategoryForm.parentId = ''
    },
    handleUploadSuccess (res, file) {
      console.log('handleUploadSuccess res:', res)
      this.addCategoryForm.logo = res.data.list[0].mediaId
      this.imageUrl = window.g.MEDIA_DOWNLOAD_URL + this.addCategoryForm.logo
    },
    handleImgRemove (file) {
      this.addCategoryForm.logo = ''
      this.imageUrl = ''
    },
    onWholeCategory () {
      this.$router.push('/wholeCategory')
    }
  }
}
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
