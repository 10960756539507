<template>
  <!-- 卡片视图区域 -->
  <el-card>
    <category-add :listQuery="listQuery" @category-list="updateCategoryList"/>

    <!-- 表格 -->
    <category-table :category-list="categoryList" @category-list="updateCategoryList"/>

    <!-- 分页区域 -->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="listQuery.page"
      :page-sizes="[10, 20, 50]"
      :page-size="listQuery.size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
  </el-card>
</template>

<script>
import CategoryAdd from './CategoryAdd'
import CategoryTable from './CategoryTable'

import { getCategoriesListRequest } from '../../../network/goods'

export default {
  name: 'CategoryCard',
  components: {
    CategoryAdd,
    CategoryTable
  },
  data () {
    return {
      listQuery: { // 查询条件
        name: '',
        page: 1,
        size: 10,
        children: 1,
        isAdmin: 1
      },
      categoryList: [],
      total: 0 // 总数据条数
    }
  },
  created () {
    this.getCategoriesList()
  },
  methods: {
    // 获取商品分类
    getCategoriesList () {
      getCategoriesListRequest(this.listQuery).then(res => {
        const result = res.data
        if (res.status !== 200) {
          return this.alertMessage('商品列表获取失败', 'error')
        }

        this.categoryList = result.data.list
        for (const item of this.categoryList) {
          if (item.logo !== '') {
            item.imageUrl = window.g.MEDIA_DOWNLOAD_URL + item.logo
          } else {
            item.imageUrl = ''
          }
        }
        this.total = result.data.total
      })
    },

    // 监听子组件触发的事件
    updateCategoryList () {
      this.getCategoriesList()
    },

    // 监听pagesize改变
    handleSizeChange (newSize) {
      this.listQuery.size = newSize
      this.getCategoriesList()
    },

    // 监听pagenum的改变
    handleCurrentChange (newPage) {
      this.listQuery.page = newPage
      this.getCategoriesList()
    }
  }
}
</script>

<style scoped>

</style>
