<template>
  <div class="editDialog">
    <el-button type="primary" size="mini" icon="el-icon-document-remove" @click="showDialog(categoryId, level, 'merge')">合并</el-button>
    <el-button type="primary" size="mini" icon="el-icon-copy-document" @click="showDialog(categoryId, level, 'move')" v-show="level!=1">移动</el-button>
    <!-- 编辑的对话框 -->
    <el-dialog
      title="编辑"
      :visible.sync="dialogVisible"
      width="50%"
      @close="dialogClose">
      <el-form :model="categoryForm" ref="categoryFormRef" label-width="80px">
        <el-form-item label="源分类" prop="name">
          <el-input v-model="categoryForm.name" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="目标分类" v-show="level==1 || operate=='move'">
          <el-select v-model="categoryForm.toCategoryId" placeholder="请选择目标分类" style="width:100%">
            <el-option
              v-for="item in selectCategoryList"
              :key="item.categoryId"
              :label="item.name"
              :value="item.categoryId">
            </el-option>
          </el-select>
        </el-form-item>
        <!--<el-form-item label="" v-show="level!=1">
          <cate-select @categoryChange="categoryChange" ref="categorySelect" v-model="categoryForm.toCategoryId" :exclude-id="categoryId"></cate-select>
        </el-form-item>-->
        <el-form-item label="目标分类" v-show="level!=1 && operate=='merge'">
          <el-cascader style="width:100%"
            ref="category"
            :props="props"
            @change="handleChange"
            v-model="value"
          ></el-cascader>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateCategory">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getCategoriesListRequest,
  getCategory,
  moveCategory
} from '../../../network/goods'

export default {
  name: 'CategoryMove',
  props: {
    categoryId: {
      type: Number,
      default: 0
    },
    level: {
      type: Number,
      default: 1
    }
  },
  data () {
    return {
      dialogVisible: false,
      categoryForm: {
        categoryId: 0,
        toCategoryId: 0
      },
      listQuery: {
        name: '', // 分类名称
        page: 0, // 当前页
        size: 0 // 每页显示多少条数据
      },
      selectCategoryList: [],
      operate: 'merge',
      excludeId: 0,
      value: [], // 多级联动的值 => 会是一个数组
      props: {
        lazy: true,
        lazyLoad: (node, resolve) => {
          const { level } = node
          const nodes = []
          const parentId = level === 0 ? 0 : node.value
          getCategoriesListRequest({ page: 0, size: 0, parentId: parentId, excludeId: this.excludeId }).then(res => {
            if (res.status !== 200) {
              return this.alertMessage('获取分类列表失败', 'error')
            }
            const result = res.data.data.list
            // 节点数组
            result.map((item) => {
              const obj = {
                value: item.categoryId,
                label: item.name,
                leaf: parentId !== 0
              }
              nodes.push(obj)
            })
            resolve(nodes)
          })
        }
      }
    }
  },
  methods: {
    // 编辑
    showDialog (categoryId, level, operate) {
      this.value = []
      this.selectCategoryList = []
      this.dialogVisible = true
      this.operate = operate
      getCategory(categoryId).then(res => {
        const result = res.data
        if (res.status !== 200) {
          return this.alertMessage('获取信息失败', 'error')
        }
        this.categoryForm = result.data
      })
      this.excludeId = categoryId
      if (level === 1 || this.operate === 'move') {
        getCategoriesListRequest({ excludeId: this.excludeId, size: 1000, page: 1 }).then(res => {
          if (res.status !== 200) {
            return this.alertMessage('获取分类列表失败', 'error')
          }
          this.selectCategoryList = res.data.data.list
        })
      }
    },
    search () {
    },

    // 监听编辑的弹框的关闭事件
    dialogClose () {
      this.$refs.categoryFormRef.resetFields()
    },

    // 点击按钮确定编辑
    updateCategory () {
      console.log('this edit category:', this.categoryForm, ',operate:', this.operate)
      // eslint-disable-next-line no-unreachable
      moveCategory({ from: this.categoryForm.categoryId, to: this.categoryForm.toCategoryId, operate: this.operate }).then(res => {
        if (res.status !== 200) {
          return this.alertMessage('移动失败', 'error')
        }
        this.alertMessage('移动成功', 'success')
        this.dialogVisible = false
        this.$emit('category-list')
      })
    },

    categoryChange (categoryId) {
      this.categoryForm.toCategoryId = categoryId
    },
    handleChange (value) {
      console.log('current selected:', value)
      if (value.length === 2) {
        this.categoryForm.toCategoryId = parseInt(value[1])
      }
    }
  }
}
</script>

<style>
.editDialog {
  display: inline-block;
  margin-right: 5px;
}
</style>
