<template>
  <div>
  <!-- 表格 -->
  <el-card style="margin: 10px 0px">
    <el-alert style="margin-bottom: 10px;" type="warning" title="合并分类：将源分类合并进目标分类；移动分类：将源分类移动到目标分类下" :closable="false" show-icon></el-alert>
  </el-card>
  <tree-table
    :data="categoryList"
    :columns="columns"
    :selection-type="false"
    :expand-type="false"
    show-index
    index-text="#"
    border
    class="tree-table">
    <template slot="icon" slot-scope="scope">
      <img :src="scope.row.imageUrl" alt="" style="width: 50px; height: 50px" v-if="scope.row.imageUrl"/>
      <span v-else>无图片</span>
    </template>
    <template slot="order" slot-scope="scope">
      <el-tag size="mini" v-if="scope.row.level === 1">一级</el-tag>
      <el-tag type="warning" size="mini" v-else>二级</el-tag>
    </template>
    <template slot="status" slot-scope="scope">
      <el-switch v-model="scope.row.status" active-text="显示" inactive-text="不显示" :active-value=1
                 :inactive-value=2 @change="changeStatus(scope.row)">
      </el-switch>
    </template>
    <!-- 操作 -->
    <template slot="opt" slot-scope="scope">
      <el-row v-show="scope.row.level==1">
        <category-edit :categoryId="scope.row.categoryId" @category-list="updateCategoryList"/>
        <category-move style="margin-left: 5px;" :categoryId="scope.row.categoryId" :level="scope.row.level" @category-list="updateCategoryList"/>
      </el-row>
      <el-row style="margin-top:10px;" v-show="scope.row.level==1">
        <el-button icon="el-icon-top" type="success" size="mini" @click="upindex(scope.row)">上移</el-button>
        <el-button icon="el-icon-bottom" type="success" size="mini"  @click="downindex(scope.row)">下移</el-button>
      </el-row>
      <el-row v-show="scope.row.level==2">
        <el-row>
          <category-edit :categoryId="scope.row.categoryId" @category-list="updateCategoryList"/>
          <el-button icon="el-icon-sort" style="margin-left: 5px;" type="success" size="mini" @click="sortSubCategory(scope.row.parentId)">排序</el-button>
        </el-row>
        <el-row style="margin-top:10px;">
          <category-move :categoryId="scope.row.categoryId" :level="scope.row.level" @category-list="updateCategoryList"/>
        </el-row>
      </el-row>
    </template>
  </tree-table>
  </div>
</template>

<script>

import CategoryEdit from './CategoryEdit'
import CategoryMove from './CategoryMove'
import { SortCategoryRequest, updateCategoryStatus } from '../../../network/goods'

export default {
  name: 'CategoryTable',
  components: {
    CategoryEdit,
    CategoryMove
  },
  props: {
    categoryList: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      // 为table指定列
      columns: [
        {
          label: '分类名称',
          prop: 'name'
        },
        {
          label: '图标',
          type: 'template',
          template: 'icon'
        },
        {
          label: '级数',
          type: 'template',
          prop: 'level',
          template: 'order'
        },
        {
          label: '状态',
          type: 'template',
          prop: 'status',
          template: 'status'
        },
        {
          label: '操作',
          type: 'template',
          template: 'opt',
          width: '300px'
        }
      ]
    }
  },
  mounted () {
  },
  methods: {
    updateCategoryList () {
      this.$emit('category-list')
    },
    changeStatus (row) {
      console.log('row state:', row)
      updateCategoryStatus({ categoryId: row.categoryId, status: row.status }).then(res => {
        if (res.status !== 200) {
          return this.alertMessage('修改分类状态失败', 'error')
        }
        this.alertMessage('修改分类状态成功', 'success')
        this.updateCategoryList()
      })
    },
    moveCategory (categoryId) {
    },
    upindex (row) {
      if (row.level === 1) {
        const index = this.categoryList.findIndex(item => {
          return item.categoryId === row.categoryId
        })
        if (index <= 0) {
          this.$message.warning('已经是第一条了！')
          return
        }
        const curData = this.categoryList[index]
        const upData = this.categoryList[index - 1]
        const sortItem = []
        sortItem.push({ categoryId: curData.categoryId, sortNum: upData.sortNum })
        sortItem.push({ categoryId: upData.categoryId, sortNum: curData.sortNum })
        SortCategoryRequest({ list: sortItem }).then(res => {
          if (res.status !== 200) {
            return this.alertMessage('上移失败', 'error')
          }
          const curSortNum = curData.sortNum
          curData.sortNum = upData.sortNum
          upData.sortNum = curSortNum
          this.categoryList.splice(index - 1, 1)
          this.categoryList.splice(index, 0, upData)
        })
      } else {
        const pIdx = this.categoryList.findIndex(item => {
          return item.categoryId === row.parentId
        })
        const index = this.categoryList[pIdx].children.findIndex(item => {
          return item.categoryId === row.categoryId
        })
        if (index <= 0) {
          this.$message.warning('已经是第一条了！')
          return
        }
        const curData = this.categoryList[pIdx].children[index]
        const upData = this.categoryList[pIdx].children[index - 1]
        const sortItem = []
        sortItem.push({ categoryId: curData.categoryId, sortNum: upData.sortNum })
        sortItem.push({ categoryId: upData.categoryId, sortNum: curData.sortNum })
        SortCategoryRequest({ list: sortItem }).then(res => {
          if (res.status !== 200) {
            return this.alertMessage('上移失败', 'error')
          }
          const curSortNum = curData.sortNum
          curData.sortNum = upData.sortNum
          upData.sortNum = curSortNum
          this.categoryList[pIdx].children.splice(index - 1, 1)
          this.categoryList[pIdx].children.splice(index, 0, upData)
          return false
        })
        return false
      }
    },
    downindex (row) {
      console.log('down:', row)
      if (row.level === 1) {
        const index = this.categoryList.findIndex(item => {
          return item.categoryId === row.categoryId
        })
        if (index >= this.categoryList.length || index === -1) {
          this.$message.warning('已经是最后一条了！')
          return
        }
        const downData = this.categoryList[index + 1]
        const curData = this.categoryList[index]
        const sortItem = []
        sortItem.push({ categoryId: curData.categoryId, sortNum: downData.sortNum })
        sortItem.push({ categoryId: downData.categoryId, sortNum: curData.sortNum })
        SortCategoryRequest({ list: sortItem }).then(res => {
          if (res.status !== 200) {
            return this.alertMessage('下移失败', 'error')
          }
          const curSortNum = curData.sortNum
          curData.sortNum = downData.sortNum
          downData.sortNum = curSortNum
          this.categoryList.splice(index + 1, 1)
          this.categoryList.splice(index, 0, downData)
        })
      } else {
        const pIdx = this.categoryList.findIndex(item => {
          return item.categoryId === row.parentId
        })
        const index = this.categoryList[pIdx].children.findIndex(item => {
          return item.categoryId === row.categoryId
        })
        if (index >= this.categoryList[pIdx].children.length || index === -1) {
          this.$message.warning('已经是最后一条了！')
          return
        }
        const downData = this.categoryList[pIdx].children[index + 1]
        const curData = this.categoryList[pIdx].children[index]
        const sortItem = []
        sortItem.push({ categoryId: curData.categoryId, sortNum: downData.sortNum })
        sortItem.push({ categoryId: downData.categoryId, sortNum: curData.sortNum })
        SortCategoryRequest({ list: sortItem }).then(res => {
          if (res.status !== 200) {
            return this.alertMessage('下移失败', 'error')
          }
          const curSortNum = curData.sortNum
          curData.sortNum = downData.sortNum
          downData.sortNum = curSortNum
          this.categoryList[pIdx].children.splice(index + 1, 1)
          this.categoryList[pIdx].children.splice(index, 0, downData)
        })
      }
    },
    sortSubCategory (categoryId) {
      this.$router.push({
        path: '/subCategory',
        query: {
          categoryId: categoryId
        }
      })
    }
  }
}
</script>

<style scoped>
.checkIcon {
  color: lightgreen;
  font-size: 17px;
}

.closeIcon {
  color: red;
  font-size: 17px;
}

.tree-table {
  margin-top: 15px;
}

.custom-table-column {
  width: 300px;
}
</style>
