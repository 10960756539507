<template>
  <div class="editDialog">
    <el-button type="primary" size="mini" icon="el-icon-edit" @click="showEditDialog(categoryId)">编辑</el-button>

    <!-- 编辑的对话框 -->
    <el-dialog
      title="编辑"
      :visible.sync="editDialogVisible"
      width="50%"
      @close="editDialogClose">
      <el-form :model="editCategoryForm" :rules="categoryFormRules" ref="editCategoryFormRef" label-width="80px">
        <el-form-item label="分类名称" prop="name">
          <el-input v-model="editCategoryForm.name"></el-input>
        </el-form-item>
        <el-form-item label="图标" prop="logo" v-if="!editCategoryForm.parentId">
          <el-upload
            class="avatar-uploader"
            :action="uploadUrl"
            :show-file-list="false"
            :on-success="handleUploadSuccess"
            :headers="imgUploadHeaders"
          >
            <img v-if="imageUrl" :src="imageUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateCategory">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getCategory,
  updateCategory
} from '../../../network/goods'

export default {
  name: 'CategoryEdit',
  props: {
    categoryId: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      editDialogVisible: false,
      categoryFormRules: {
        name: [
          {
            required: true,
            message: '请输入分类名称',
            trigger: 'blur'
          }
        ]
      },
      editCategoryForm: {
        categoryId: 0,
        name: '',
        logo: '',
        parentId: 0 // 父级分类的id
      },
      uploadUrl: window.g.MEDIA_URL + '/upload',
      imgUploadHeaders: { // 上传图片控件的header
        Authorization: sessionStorage.getItem('token')
      },
      imageUrl: ''
    }
  },
  methods: {
    // 编辑
    showEditDialog (categoryId) {
      this.editDialogVisible = true
      getCategory(categoryId).then(res => {
        const result = res.data
        if (res.status !== 200) {
          return this.alertMessage('获取信息失败', 'error')
        }
        this.editCategoryForm = result.data
        if (this.editCategoryForm.logo !== '') {
          this.imageUrl = window.g.MEDIA_DOWNLOAD_URL + this.editCategoryForm.logo
        }
      })
    },

    // 监听编辑的弹框的关闭事件
    editDialogClose () {
      this.$refs.editCategoryFormRef.resetFields()
      this.imageUrl = ''
    },

    // 点击按钮确定编辑
    updateCategory () {
      this.$refs.editCategoryFormRef.validate(valid => {
        if (!valid) return
        console.log('this edit category:', this.editCategoryForm)
        // return
        // eslint-disable-next-line no-unreachable
        updateCategory(this.editCategoryForm).then(res => {
          if (res.status !== 200) {
            return this.alertMessage('修改失败', 'error')
          }
          this.alertMessage('修改成功', 'success')
          this.editDialogVisible = false
          this.$emit('category-list')
        })
      })
    },
    handleUploadSuccess (res, file) {
      console.log('handleUploadSuccess res:', res)
      this.editCategoryForm.logo = res.data.list[0].mediaId
      this.imageUrl = window.g.MEDIA_DOWNLOAD_URL + this.editCategoryForm.logo
    },
    handleImgRemove (file) {
      this.editCategoryForm.logo = ''
      this.imageUrl = ''
    }
  }
}
</script>

<style scoped>
.editDialog {
  display: inline-block;
  margin-right: 5px;
}
</style>
